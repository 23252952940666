<template>
<div>
  <section class="tourism__direction desktop-tourism__direction">
    <!-- <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/percent.svg" alt />
        <b class="text brand__text tourism__direction-text">{{ $t("Promotions") }}</b>
      </a>
    </div> -->
    <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/watch.svg" alt />
        <p class="text brand__text">{{ $t("Last moment") }}</p>
      </a>
    </div>
    <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/thumb-up.svg" alt />
        <p class="text brand__text">{{ $t("Recommended") }}</p>
      </a>
    </div>
    <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/map.svg" alt />
        <p class="text brand__text">{{ $t("Popular") }}</p>
      </a>
    </div>
    <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/dice.svg" alt />
        <p class="text brand__text tourism__direction-text">{{ $t("Casino") }}</p>
      </a>
    </div>
    <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <div class="d-flex justify-content-center align-items-center">
          <img class="brand__img mx-5" src="/assets/img/ball.svg" alt />
          <img class="brand__img mx-5" src="/assets/img/microphone.svg" alt />
        </div>
        <p class="text brand__text tourism__direction-text">{{ $t("Sport & Events") }}</p>
      </a>
    </div>
    <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/umbrella.svg" alt />
        <p class="text brand__text">{{ $t("Sun & Fun") }}</p>
      </a>
    </div>
    <!-- <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/podium.svg" alt />
        <b class="text brand__text">{{ $t("Exhibitions") }}</b>
      </a>
    </div> -->
    <!-- <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/microphone.svg" alt />
        <b class="text brand__text">{{ $t("Impressions") }}</b>
      </a>
    </div> -->
    <!-- <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/tree.svg" alt />
        <b class="text brand__text">{{ $t("Sylvester") }}</b>
      </a>
    </div>
    <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/family.svg" alt />
        <b class="text brand__text">{{ $t("To_ALL_FAMILY") }}</b>
      </a>
    </div>
    <div class="brand tourism__direction-item">
      <a class="link brand__link tourism__direction-link" href="tourism-category.html">
        <img class="brand__img" src="/assets/img/ski.svg" alt />
        <b class="text brand__text">{{ $t("Attractiveness") }}</b>
      </a>
    </div> -->
  </section>
  <div
    class="tourismDirectionModal modal-scroll-bar"
    v-show="isModalVisible"
  >
    <h3 class="text-center">
      {{ $t('Top categories quick select') }}
    </h3>
    <div class="tourism__direction">
      <div class="brand tourism__direction-item">
        <a class="link brand__link tourism__direction-link" href="tourism-category.html">
          <img class="brand__img" src="/assets/img/watch.svg" alt />
          <p class="text brand__text">{{ $t("Last moment") }}</p>
        </a>
      </div>
      <div class="brand tourism__direction-item">
        <a class="link brand__link tourism__direction-link" href="tourism-category.html">
          <img class="brand__img" src="/assets/img/thumb-up.svg" alt />
          <p class="text brand__text">{{ $t("Recommended") }}</p>
        </a>
      </div>
      <div class="brand tourism__direction-item">
        <a class="link brand__link tourism__direction-link" href="tourism-category.html">
          <img class="brand__img" src="/assets/img/map.svg" alt />
          <p class="text brand__text">{{ $t("Popular") }}</p>
        </a>
      </div>
      <div class="brand tourism__direction-item">
        <a class="link brand__link tourism__direction-link" href="tourism-category.html">
          <img class="brand__img" src="/assets/img/dice.svg" alt />
          <p class="text brand__text tourism__direction-text">{{ $t("Casino") }}</p>
        </a>
      </div>
      <div class="brand tourism__direction-item">
        <a class="link brand__link tourism__direction-link" href="tourism-category.html">
          <div class="d-flex justify-content-center align-items-center">
            <img class="brand__img mx-5" src="/assets/img/ball.svg" alt />
            <img class="brand__img mx-5" src="/assets/img/microphone.svg" alt />
          </div>
          <p class="text brand__text tourism__direction-text">{{ $t("Sport & Events") }}</p>
        </a>
      </div>
      <div class="brand tourism__direction-item">
        <a class="link brand__link tourism__direction-link" href="tourism-category.html">
          <img class="brand__img" src="/assets/img/umbrella.svg" alt />
          <p class="text brand__text">{{ $t("Sun & Fun") }}</p>
        </a>
      </div>
    </div>
    <button 
      class="primary-btn mt-10 close-direction_btn bg-red6"
      @click="closeModal"  
    >
      {{ $t("Cancel") }}
    </button>
  </div>
</div>
</template>

<script>
export default {
  name: 'TourismDirection',
  data() {
    return {
      isModalVisible: false
    }
  },
  methods: {
    closeModal() {
      $("html").css("overflow", "auto");
      this.isModalVisible = false;
    },
    openModal() {
      $("html").css("overflow", "hidden");
      this.isModalVisible = true;
    },
  }
}
</script>

<style lang="scss" scoped>
.tourism__direction-item {
  height: unset;
  .brand__img {
    height: 36px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 767px) {
  .desktop-tourism__direction {
    display: none;
  }
  .tourismDirectionModal {
    position: fixed;
    top: 60px;
    width: 100vw;
    left: 0;
    right: 0;
    background-color: #DFE0E3;
    height: calc(100vh - 60px);
    padding: 15px 30px;
    z-index: 22;
    .tourism__direction-item {
      flex-basis: calc(50% - 10px);
      margin-top: 10px;
    }
    .close-direction_btn {
      width: auto;
      padding: 0 30px;
      border-radius: 5px;
      margin: 0 auto;
    }
  }
}
</style>