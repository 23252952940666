<template>
  <div>
    <div class="df-c p-relative card-header tourism-category__place" style="background-image: url('/assets/img/berlin2.png')">
      <h3 class="tourism-category__place-name fs23">Cairo</h3>
      <CategoryShare></CategoryShare>
      <CategoryStar></CategoryStar>
    </div>
    <div class="card-body bg-white pb-2">
      <slot name="title"></slot>
      <FlightDesDataStar class="mt-5">
        <template slot="additionalData">
          <button class="primary-btn small-btn bg-blue5 df-c fs14 color-primary full-detail-btn mt-7">
            {{ $t( "Go to deal" ) }}
          </button>
        </template>
      </FlightDesDataStar>
      <div class="d-flex flex-column align-items-center">
        <div class="mt-10 fs14 text-center">
          Starting from: <b class="fs16">300 ₪</b>
        </div>
        <button class="primary-btn small-btn fs16 bg-3">
          {{ $t( "Explore more Dates" ) }}
        </button>
        <p class="fs11 mt-2">
          * {{ $t( "The price is for the specific flight shown" ) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import RateStar from '../../RateStar';
import CategoryShare from '../../CategoryShare';
import CategoryStar from '../CategoryStar';
import FlightDesDataStar from '../FlightDesDataStar';
export default {
  name: "FlightTourismVacationItem",
  components: {
    RateStar,
    CategoryShare,
    CategoryStar,
    FlightDesDataStar
  },
}
</script>

<style lang="scss" scoped>
  .card-header {
    height: 180px;
    background-size: cover;
  }
  .fs16 {
    font-size: 16px !important;
  }
  .full-detail-btn {
    padding: 0 3px;
    img {
      margin-right: 5px;
      width: 17px;
    }
  }
</style>