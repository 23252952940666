<template>
  <div class="category__star">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.12"
      height="25"
      viewBox="0 0 26.12 25"
    >
      <defs:style>
        .star-stroke { fill: #fff; }
      </defs:style>
      <path
        class="star-stroke"
        d="M45.282,8.361l-7.133-1.04L34.961.857a1.564,1.564,0,0,0-2.8,0L28.97,7.321l-7.133,1.04a1.563,1.563,0,0,0-.864,2.666l5.161,5.029-1.221,7.1A1.561,1.561,0,0,0,27.178,24.8l6.381-3.354L39.941,24.8a1.563,1.563,0,0,0,2.265-1.645l-1.221-7.1,5.16-5.029a1.563,1.563,0,0,0-.864-2.666Zm-5.971,7.148,1.357,7.909-7.109-3.73-7.1,3.735,1.357-7.909L22.062,9.9l7.943-1.152,3.554-7.2,3.554,7.2L45.057,9.9l-5.746,5.6Z"
        transform="translate(-20.5 0.013)"
      />
    </svg>
  </div>
</template>

<script>

export default {
  name: 'CategoryStar',
};
</script>

<style lang="scss">
</style>
