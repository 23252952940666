<template>
  <div class="trip-summary bdbr-3 bd-radius d-flex align-items-center">
    <div class="flight-company">
      <img src="/assets/img/icons/luftansa.svg" alt="" class="mb-7">
      <img src="/assets/img/icons/Austrian_Airlines.svg" alt="">
    </div>
    <div class="mx-15">
      <p>{{ summary_data.destinationName }}, {{ summary_data.destinationCountry }}</p>
      <p class="d-flex align-items-center">
        <img src="/assets/img/icons/arrows-h-alt.svg" alt="">
        <span class="fs11">arrows-h-alt</span> 
      </p>
    </div>
    <div>
      TLV
    </div>
    <div class="mx-5 text-center">
      <p class="fs16">
        {{ summary_data.departureDate }}
      </p>
      <p class="fs11">
        12:45 - 18:25
      </p>
      <p class="fs11">
        1h 45m
      </p>
    </div>
    <img src="/assets/img/icons/flight.svg" alt="">
    <div class="mx-5">
      BRL
    </div>
    <div class="mx-5 text-center">
      <p class="fs16">
        14/04/21
      </p>
      <p class="fs11">
        12:45 - 18:25
      </p>
      <p class="fs11">
        1h 45m
      </p>
    </div>
    <div class="fs14">
      5 Days
    </div>
  </div>
</template>

<script>
export default {
  name: "TripSummary",
  props: {
    summary_data: {
      type: Object,
      required: true,
      default: {}
    },
  },
}
</script>

<style lang="scss" scoped>
  .trip-summary {
    padding: 5px 10px;
    .flight-company {
      img {
        // height: 14px;
        width: 80px;
        display: block;
      }
    }
  }

  @media (max-width: 670px) {
    .trip-summary {
      flex-direction: column;
    }
  }
</style>