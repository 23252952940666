<template>
   <modal
    :width="1150"
    :adaptive="true"
    class="fullDetailModal CEModal modal-scroll-bar"
    name="FullDetailModal"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>

    <div class="d-flex align-items-center flex-wrap mt-10">
      <h4 class="mb-5">{{ $t( "Trip Summary" ) }}</h4>
      <TripSummary class="mx-10 mb-5" :summary_data="flight_data"></TripSummary>
    </div>
    <div class="row mt-7">
      <div class="col-md-4">
        <FligntTicket></FligntTicket>
      </div>
      <div class="col-md-4">
        <FligntTicket></FligntTicket>
      </div>
      <div class="col-md-4">
        <BaggageInfo></BaggageInfo>
        <CovideNotify class="mt-7"></CovideNotify>
      </div>
    </div>
    <p class="fs14 my-10">
      4 nights in Berln then <b>depart from Willy Brandt (BER)</b>
    </p>
    <div class="row pt-10">
      <div class="col-md-9">
        <HotelData></HotelData>
      </div>
      <div class="col-md-3 d-flex align-items-end">
        <button class="primary-btn primary-btn_sm bg-green6">
          {{ $t( "Book Flight" ) }}
        </button>
        <button class="primary-btn primary-btn_sm bg-grey8" @click="closeModal()">
          {{ $t( "Close" ) }}
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import  FligntTicket from "../tradeShow/FligntTicket.vue";
import  BaggageInfo from "../tradeShow/BaggageInfo.vue";
import  CovideNotify from "../tradeShow/CovideNotify.vue";
import  TripSummary from "../tradeShow/TripSummary.vue";
import  HotelData from "../tradeShow/HotelData.vue";

export default {
  name: 'FullDetailModal',
  components: {
    FligntTicket,
    BaggageInfo,
    CovideNotify,
    TripSummary,
    HotelData
  }, 
  props: {
    flight_data: {
      type: Object,
      required: true,
      default: () => {}
    },
  },
  methods: {
    closeModal() {
      this.$modal.hide("FullDetailModal");
    },
  }
}
</script>

<style lang="scss">
  .fullDetailModal .vm--modal{
    padding: 20px;
  }
</style>

